import { SpecialtyScoreColor } from "../models/IConsultantSpecialty"
import { IWorkAssignment } from "../models/IWorkAssignment"
import { blue, green, grey, red, yellow } from "@mui/material/colors"
import { linearProgressClasses, Theme } from "@mui/material"
import moment from "moment"
import { SxProps } from "@mui/system"
import { IExpenseReport } from "../models/IExpenseReport"

export const WA_DAILY_BLUE = blue[500] + "44"
export const WA_DAILY_YELLOW = yellow[500] + "44"
export const WA_DAILY_RED = red[500] + "44"

export const styles = {
  progress: {
    height: 10,
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
    },
  },
  locations: {
    list: {
      paddingTop: 0,
      "& li": {
        paddingTop: 0,
        "& div": {
          marginTop: 0,
        },
      },
    },
  },
  marginTopPaper: {
    padding: 2,
    marginTop: 20,
    color: "text.secondary",
  },
  specialty: {
    avatar: (score: number) => (theme: Theme) => ({
      bgcolor: theme.palette.common.white,
      border: `1px solid ${SpecialtyScoreColor[score - 1]}`,
      color: theme.palette.common.black,
    }),
    chip: (score: number) => ({
      marginRight: 1,
      marginBottom: 1,
      borderColor: SpecialtyScoreColor[score - 1],
    }),
  },
  review: {
    chip: () => ({
      marginBottom: 1,
    }),
  },
  languages: {
    chip: {
      marginRight: 1,
      marginBottom: 1,
    },
  },
  consultant: {
    identifier: {
      marginLeft: 3,
      marginRight: 3,
    },
  },
  index: {
    search: {
      padding: 2,
    },
    results: {
      overflow: "hidden",
    },
  },
  pageFunc: (isSmall: boolean) => ({
    padding: isSmall ? 1 : 2,
  }),
  page: {
    padding: 2,
  },
  viewProperty: {
    marginTop: 0.3,
    marginBottom: 0.3,
  },
  tabsBox: {
    marginTop: 1,
    borderBottom: 1,
    borderColor: "divider",
  },
  tableSelect: {
    cursor: "pointer",
  },
  accordion: (theme: Theme) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }),
  expenseReportRowStyle: (expenseReport: IExpenseReport) => {
    let receivedUnderline = {}
    if (expenseReport.received === null) {
      receivedUnderline = {
        borderBottom: `1px solid red`,
      }
    }

    if (
      expenseReport.report_ready !== null &&
      expenseReport.received !== null &&
      expenseReport.paid !== null &&
      expenseReport.confirmed !== null
    ) {
      return {
        ...receivedUnderline,
        background: grey[200],
      }
    }
    if (expenseReport.notes !== null && expenseReport.notes !== "" && expenseReport.total_admin_hours > 0) {
      return {
        ...receivedUnderline,
        background: green[100],
      }
    }
    if (expenseReport.total_admin_hours > 0) {
      return {
        ...receivedUnderline,
        background: yellow[100],
      }
    }
    if (expenseReport.notes !== null && expenseReport.notes !== "") {
      return {
        ...receivedUnderline,
        background: blue[100],
      }
    }
    return {
      ...receivedUnderline,
      background: "inherit",
    }
  },
  waIsLate: (wa: IWorkAssignment): SxProps<Theme> => ({
    color: wa.is_report_sent_account_late ? red[500] : undefined,
  }),
  waTechReviewHoursStyle: (wa: IWorkAssignment) => ({
    background:
      (wa.tech_review_2_needed && wa.tech_review_2_hours > 2) || (!wa.tech_review_2_needed && wa.tech_review_hours > 2)
        ? WA_DAILY_RED
        : "inherit",
  }),
  waAdminReviewHoursStyle: (wa: IWorkAssignment) => ({
    background: wa.admin_review_hours > 2 ? WA_DAILY_RED : "inherit",
  }),
  waIsLateRowStyle: (wa: IWorkAssignment) => ({
    background: wa.is_report_sent_account_late ? WA_DAILY_RED : "inherit",
  }),
  consultantReportIsLate: (wa: IWorkAssignment): SxProps<Theme> => ({
    color: wa.is_consultant_report_late ? red[500] : "inherit",
  }),
  waDaily: (wa: IWorkAssignment): SxProps<Theme> => {
    if (
      wa.progress_consultant_visit_complete !== null &&
      wa.progress_report_review_admin_sign_off !== null &&
      wa.progress_report_review_tech_sign_off === null
    ) {
      const now = moment()
      const visit10Days = moment(wa.progress_consultant_visit_complete).add(10, "days")
      const visit20Days = moment(wa.progress_consultant_visit_complete).add(20, "days")

      if (visit10Days.isAfter(now)) {
        return {
          whiteSpace: "nowrap",
          background: WA_DAILY_BLUE,
        }
      }
      if (visit10Days.isBefore(now) && visit20Days.isAfter(now)) {
        return {
          whiteSpace: "nowrap",
          background: WA_DAILY_YELLOW,
        }
      }
      if (visit20Days.isBefore(now)) {
        return {
          whiteSpace: "nowrap",
          background: WA_DAILY_RED,
        }
      }
    }
    return { whiteSpace: "nowrap" }
  },
}
