import { IWorkAssignment } from "./IWorkAssignment"
import { IConsultant } from "./main/IConsultant"

export const WORK_ASSIGNMENT_CONSULTANT_ENDPOINT = "work_assignment_consultants"
export const EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT = "expense_report_work_assignment_consultants"
export const CONSULTANTS_WORK_ASSIGNMENT_ENDPOINT = "consultants_work_assignment"

export interface IWorkAssignmentConsultant {
  id: number
  amount: number
  total_amount: number
  accepted: boolean
  declined: boolean
  request_sent: boolean
  hourly_rate: boolean
  hours: number
  consultant_comment: string
  message: string
  priority: number
  created: string
  updated: string
  work_assignment: IWorkAssignment | number
  consultant: IConsultant | number
}
