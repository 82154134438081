import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material"

import WorkflowPage from "../../shared/components/WorkflowPage"
import MyWorkAssignments, { useCurrentUserFilters } from "./components/MyWorkAssignments"
import SettingsIcon from "@mui/icons-material/Settings"
import { IWidget, widgets as initWidgets } from "./components/widgets"
import DialogControls from "../../shared/components/DialogControls"
import { useLocalStorage } from "beautiful-react-hooks"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import { styles } from "../../shared/styling/general"
import { PowerBIEmbed } from "powerbi-client-react"
import { powerBiReport } from "../../shared/repositories/reports_repository"
import "../../shared/styling/power_bi.css"
import useContentHeight from "../../shared/hooks/useContentHeight"
import HelpDocsDrawer from "../../shared/components/help/HelpDocsDrawer"
import SelectFilteredSingle from "../../shared/components/SelectFilteredSingle"
import { IListItem } from "../../shared/models/component/IListItem"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { STAFF_ENDPOINT } from "../../shared/models/IUser"

const userRepository = new RestRepository<IListItem>(STAFF_ENDPOINT)

/**
 * Dashboard main page that will show widgets relevant to the user.
 *
 * @returns {React.FC} the Dashboard page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const [widgets, setWidgets] = useLocalStorage<IWidget[] | undefined>("DASHBOARD_WIDGETS", initWidgets)
  const [ae, setAe] = useLocalStorage<IListItem | null>("DASHBOARD_AE", null)
  const [selectedAe, setSelectedAe] = useState<IListItem | null>(ae)
  const [open, setOpen] = useState(false)
  const isSmall = useMediaQuery(useTheme().breakpoints.down("md"))
  const handleOpen = useCallback(() => setOpen(true), [])
  const { getFilters } = useCurrentUserFilters()
  const { tab, handleTabChange } = useTabPanel()
  const [embedConfig, setEmbedConfig] = useState<any>()
  const height = useContentHeight(-22)

  const handleClose = useCallback(() => {
    setAe(selectedAe)
    setOpen(false)
  }, [selectedAe])

  const handleAe = useCallback((ae1: IListItem | null) => {
    setSelectedAe(ae1)
  }, [])

  const handleToggle = useCallback(
    (widget: IWidget) => () => {
      setWidgets(widgets => {
        return widgets !== undefined
          ? [
              ...widgets.map(widget2 => {
                if (widget.id === widget2.id) {
                  return {
                    ...widget2,
                    show: !widget2.show,
                  }
                }
                return widget2
              }),
            ]
          : []
      })
    },
    [widgets]
  )

  useEffect(() => {
    // noinspection UnreachableCodeJS
    // eslint-disable-next-line no-unreachable
    void (async () => {
      try {
        const report = await powerBiReport()
        setEmbedConfig(report)
      } catch (e) {}
    })()
  }, [])

  return (
    <WorkflowPage margin={0} footer={false} noPaper>
      <Grid container alignItems="end">
        <Grid item xs>
          <Box sx={styles.tabsBox}>
            <Tabs value={tab} onChange={handleTabChange}>
              <Tab label="Dashboard" />
              <Tab label="Analytics" />
            </Tabs>
          </Box>
        </Grid>
        <Grid item>
          {tab === 0 && <HelpDocsDrawer showButton showButtonLabel={false} pageId={226} />}
          {tab === 1 && <HelpDocsDrawer showButton showButtonLabel={false} pageId={227} />}
          <IconButton onClick={handleOpen}>
            <SettingsIcon />
          </IconButton>
          <Dialog onClose={handleClose} maxWidth="xs" fullWidth open={open} fullScreen={isSmall} scroll="paper">
            <DialogTitle>Available Widgets</DialogTitle>
            <DialogContent
              sx={{
                p: 0,
                pl: 1,
                pr: 1,
              }}
            >
              <Box sx={{ p: 2 }}>
                <SelectFilteredSingle name="executive" label="AE" defaultValue={selectedAe} repository={userRepository} onChange={handleAe} />
              </Box>
              <List
                sx={{
                  pt: 0,
                  height: isSmall ? "inherit" : 500,
                }}
              >
                {widgets?.map(widget => (
                  <ListItem key={widget.id} secondaryAction={<Checkbox edge="end" onChange={handleToggle(widget)} checked={widget.show} />}>
                    <ListItemText primary={widget.title} secondary={widget.description} />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <DialogControls buttonLabel="Close" onSave={handleClose} />
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <TabPanel index={0} value={tab}>
        <Grid container spacing={2}>
          {widgets
            ?.filter(widget => widget.show)
            .map(widget => (
              <Grid key={widget.id} item lg={4} sm={12}>
                <MyWorkAssignments
                  filters={getFilters(widget.filters, ae)}
                  order={widget.order}
                  title={widget.title}
                  description={widget.description}
                  ae={ae}
                />
              </Grid>
            ))}
        </Grid>
      </TabPanel>
      <TabPanel index={1} value={tab}>
        <Box sx={{ height }}>
          <Box sx={{ height: 42, width: `calc(100% - 10px)`, background: "white", position: "absolute", zIndex: 1000 }} />
          <PowerBIEmbed
            cssClassName="power-bi-report"
            embedConfig={{
              type: "report",
              embedUrl: embedConfig?.embed_info.reportConfig[0].embedUrl,
              accessToken: embedConfig?.embed_info.accessToken,
              tokenType: 1,
            }}
          />
        </Box>
      </TabPanel>
    </WorkflowPage>
  )
}

export default IndexPage
