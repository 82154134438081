import { Badge, Box, Grid, IconButton, Link, Paper, Popover, Typography } from "@mui/material"
import * as React from "react"
import { MouseEvent, ReactElement, useCallback, useState } from "react"
import { getConsultantPhone, IConsultant } from "../models/main/IConsultant"
import { Link as BrowserLink } from "@reach/router"
import { CONSULTANTS_VIEW_URL } from "../../config/urls"
import { InfoOutlined } from "@mui/icons-material"
import { IWorkAssignmentConsultant } from "../models/IWorkAssignmentConsultant"
import WorkAssignmentConsultantEdit from "../../pages/work_assignments/components/WorkAssignmentConsultantEdit"
import ConsultantRating from "./consultants/ConsultantRating"

interface IProps {
  consultant: IConsultant
  label?: string
  amount?: ReactElement | null
  hours?: ReactElement | string | number | null
  wac?: IWorkAssignmentConsultant
  onChange?: () => void
}

/**
 * Displays the consultant as a card.
 * todo: this needs to be rebuilt because it only appears on the wa view.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the consultant card.
 */
const ConsultantCard: React.FunctionComponent<IProps> = (props: IProps) => {
  const { consultant, label = "Consultant", amount, hours = null, wac, onChange } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => setAnchorEl(null), [])
  const open = Boolean(anchorEl)

  const handleFeeChange = useCallback(() => {
    if (onChange !== undefined) {
      onChange()
    }
  }, [])

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            m: 1,
            p: 1,
          }}
        >
          <ConsultantRating rating={consultant.rating} />
          <Typography variant="body2">
            <Link href={`mailto:${consultant.first_name} ${consultant.last_name}<${consultant.email}>`}>{consultant.email}</Link>
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            <Link href={`tel:${getConsultantPhone(consultant)}`}>{getConsultantPhone(consultant)}</Link>
          </Typography>
        </Box>
      </Popover>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Badge
          color="info"
          badgeContent={label}
          sx={{
            display: "block",
            "& .MuiBadge-badge": {
              mt: -0.1,
              mr: 8,
            },
          }}
        >
          <Grid container alignItems="center" spacing={1} sx={{ pt: 0.5 }}>
            <Grid item xs>
              <Typography variant="h5" component="div">
                <Link component={BrowserLink} to={`${CONSULTANTS_VIEW_URL}/${consultant.id}`}>
                  {consultant.first_name} {consultant.last_name}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              {wac !== undefined && <WorkAssignmentConsultantEdit wac={wac} onChange={handleFeeChange} useButtonWithAmount />}
              {amount !== null && wac === undefined && <Typography variant="h6">{amount}</Typography>}
              {hours !== null && <Typography variant="h6">{hours} hrs</Typography>}
            </Grid>
            <Grid item>
              <IconButton color="primary" size="small" component="span" onClick={handleClick}>
                <InfoOutlined />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <ConsultantRating rating={consultant.rating} />
            </Grid>
          </Grid>
        </Badge>
      </Paper>
    </>
  )
}

export default ConsultantCard
