import { IListItem } from "./component/IListItem"
import { IStorageFile } from "./IStorageFile"
import { IMinimalFile } from "./IFile"

export const REPORT_FORMAT_ENDPOINT = "report_formats"

export interface IReportFormat extends IListItem, IMinimalFile {
  id: number
  name: string
  ext: string
  description: string
  file: IStorageFile
}
